<template>
  <section>
    <div class="background-style" v-if="visible" @click="closeModal"></div>
    <transition name="modal">
      <div
        v-if="visible"
        class="addtocard"
      >
        <div class="my-5 wraper">
          <div class="greenline d-flex flex-row">
            <h1 class="mx-auto my-2">Получить консультацию специалиста</h1>
            <img
              class="close my-auto"
              src="@/assets/img/Close.svg"
              @click="closeModal"
            />
          </div>

          <div class="cart__block">
            <div class="cart__image">
              <img :src="product.image" />
            </div>
            <div class="cart__text">
              <h4>{{ product.name }}</h4>
              <p>
                Средство для лечения эрозии<br />
                Количество {{ +product.status || 1 }}
              </p>
              <h5>{{ product.price * product.status }}</h5>
            </div>
          </div>

          <div class="total">
            <h6>Итого в корзине товаров на сумму:</h6>
            <h3>{{ total }}</h3>
          </div>
          <div class="addtocard__form">
            <input class="input__field" type="text" v-model.trim="firstName" placeholder="Имя" />
            <input class="input__field" type="text" v-model.trim="lastName" placeholder="Фамилия" />
            <input class="input__field" type="text" v-model.trim="phone" placeholder="Телефон" />
          </div>
          <div class="addtocard__confirm">
            <div class="d-flex align-items-center p-3">
              <input id="confirm" type="checkbox" v-model="confirmed"/>
              <label class="mx-3" for="confirm"
                >Я согласен на обработку персональных данных</label
              >
            </div>
            <button @click="goToCart(product.product_id)">Получить консультацию специалиста</button>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import PublicAPI from "@/api/PublicAPI";
import { mapGetters } from 'vuex';

export default {
  emits: ["close"],
  data() {
    return {
      firstName: '',
      lastName: '',
      phone: '',
      confirmed: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      reqired: true,
    },
  },
  computed: {
    ...mapGetters('cart', ["getTotal"]),

    total() {
        // this.getTotal.toString().replace(/(.)(?=(\d{3})+$)/g, "$1 ") + " ТГ."
      return Math.round(this.getTotal) + this.product.currency
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async goToCart(id) {
      if(this.firstName && this.lastName && this.phone && this.confirmed.length) {
      const fastResponse = PublicAPI.askKet('feed/rest_api/fastorder', {
        product_id: id,
        firstName: this.firstName,
        lastName: this.lastName,
        telephone: this.phone
      })
      console.log(fastResponse)
      this.firstName = '',
      this.lastName = '',
      this.telephone = '',
      // this.$router.push({ path: "/checkout" });
      this.closeModal();
      } else {
        alert('Please fill all fields at the fast order and confirmed sending your data!')
      }
    },
  },
};
</script>

<style scoped>
.background-style {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
}

.addtocard {
  width: 630px;
  display: flex;
  align-items: center;
  margin: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.addtocard .wraper {
  background-color: #ffffff;
  border-radius: 5px;
}

.addtocard .greenline {
  background: #75b051;
  width: 100%;
}

.addtocard h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;

  color: #ffffff;
}

.cart__block {
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0;
}
.cart__image {
  max-width: 200px;
}

.cart__image img {
  width: 100%;
  height: auto;
}

.cart__text {
  padding: 30px 0;
}
.addtocard .wraper h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
.addtocard .wraper h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 206.5%;
  /* or 37px */

  color: #7a7a7a;
}
.addtocard .wraper p {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;
  margin-bottom: 0;

  color: #7a7a7a;
}
.addtocard .wraper h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 206.5%;
  /* or 37px */

  color: #000000;
}
.addtocard .wraper h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 206.5%;
  /* or 45px */

  color: #7a7a7a;
}
.addtocard .wraper hr {
  height: 2px;
  background: #75b051;
}
.addtocard .close {
  margin-right: 15px;
  cursor: pointer;
}
.addtocard .wraper {
  width: 100%;
}

.addtocard .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #75b051;
  border-bottom: 1px solid #75b051;
  padding: 0 3rem;
}

.addtocard__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.addtocard__form .input__field {
  text-align: center;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}
.addtocard__confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.addtocard__confirm div > label {
  font-size: 10px;
  line-height: 12px;
  color: #7a7a7a;
}

.addtocard__confirm > button {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background-color: #75b051;
  border-radius: 5px;
  padding: 10px 70px;
  margin-bottom: 20px;
}

.btn {
  background-color: #7ab456;
  color: #ffffff;
  font-size: 18px;
  width: 241px;
  height: 50px;
}

.modal-enter-active {
  animation: modal 0.5s ease-out;
}

.modal-leave-active {
  animation: modal 0.5s ease-in reverse;
}

@keyframes modal {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.2);
  }
}

@media (max-width: 768px) {
  .addtocard {
    width: 100%;
    overflow: auto;
  }
}

@media (max-width: 550px) {
.addtocard .total {
  padding: 0 10px;
}
.addtocard .wraper h6 {
  font-size: 12px;
}
.addtocard .wraper h3 {
  font-size: 18px;
}
} 
</style>
